import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
          <div style={{display:"flex",flexDirection:'column',marginBottom:'0.5rem'}}>
              <div>ARK分析→ <a href="https://arkk.vercel.app/ja" target="_blank" rel="noopener noreferrer">ARK Graph</a></div>
              <div>ARK速報→<a href="https://cf-arkk.podman.workers.dev/" target="_blank" rel="noopener noreferrer">ARK Autopilot</a></div>
          </div>
        {new Date().getFullYear()}, Written by
        {` `}
        <a href="https://twitter.com/podsay">Podman.dev</a>
      </footer>
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "6ba8708aec7b4ab59c1f78e26189e342", "spa": true}'/>
    </div>
  )
}

export default Layout
